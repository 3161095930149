.panel {
    width: 92%;
    margin: 0 auto;
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &_logo {
        width: 60px;
        height: 60px;
    }

    &_btn {
        position: relative;
        width: 28px;
        height: 28px;
        padding: 0;
        border: none;
        background: none;
        cursor: pointer;

        svg {
            width: 100%;
            height: 100%;
        }
    }

    &_nav {
        display: none;

    }
}

@media screen and (min-width: 768px) {

    .panel {
        &_logo {
            width: 90px;
            height: 90px;
        }

        &_btn {
            display: none;
        }

        &_nav {
            display: flex;
            align-items: center;
            column-gap: 30px;

            &_item {
                cursor: pointer;
                text-decoration: none;
                color: #FFF;
                font-family: Murecho;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.8px;
                transition-duration: 0.3s;

                &:hover {
                    color: #E4A951;
                    text-decoration: underline;
                    transition-duration: 0.5s;
                }
            }
        }
    }
}

@media screen and (min-width: 1200px) {
    .panel {



        &_nav {

            &_item {
                font-size: 22px;
            }
        }

    }
}

@media screen and (min-width: 1440px) {
    .panel {
        padding-top: 26px;

        &_logo {
            width: 90px;
            height: 90px;
        }

        &_nav {
            column-gap: 60px;

        }

    }
}