.gallery {
    width: 100%;
    padding: 80px 0;
    background: #000;

    &_title {
        color: #E4A951;
        font-family: BIZ UDPGothic;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        text-decoration-line: underline;
    }

    &_content {
        display: flex;
        justify-content: center;
        margin-top: 80px;
        column-gap: 40px;

        &_left {
            flex: 1;
            display: flex;
            flex-direction: column;
            row-gap: 40px;

            &_top {
                width: 100%;
                height: 598px;
            }

            &_bottom {
                width: 100%;
                height: 729px;
            }
        }

        &_right {
            flex: 2;
            display: flex;
            flex-direction: column;
            row-gap: 40px;

            &_top {
                display: flex;
                column-gap: 40px;

                &_left {
                    flex: 1;
                    height: 698px;
                }

                &_right {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    row-gap: 40px;

                    &_top {
                        width: 100%;
                        height: 358px;
                    }

                    &_bottom {
                        width: 100%;
                        height: 300px;
                    }
                }
            }

            &_bottom {
                width: 100%;
                height: 629px;
            }
        }
    }

    &_contentT {
        display: none;
        column-gap: 40px;
        width: 100%;
        margin: 80px auto 0 auto;

        &_left {
            width: calc(50% - 20px);
            display: flex;
            flex-direction: column;
            row-gap: 40px;
        }

        &_right {
            width: calc(50% - 20px);
            display: flex;
            flex-direction: column;
            row-gap: 40px;

            &_bottom {
                height: 694px;
            }
        }
    }
}

.wrapper {
    width: calc(100% - 230px);
    max-width: 1440px;
    margin: 0 auto;
}

@media screen and (max-width: 450px) {
    .wrapper {
        width: 100%;
        max-width: 360px;
    }
}

@media screen and (max-width:767px) {
    .gallery {
        padding: 36px 0;

        &_title {
            text-align: center;


            font-size: 18px;

        }
    }

    .wrapper {
        width: 92%;

    }

}

@media screen and (min-width: 768px) and (max-width: 1200px) {
    .wrapper {
        max-width: 768px;
        width: 92%;
    }

    .gallery {
        &_contentT {
            display: flex;
            width: 100%;
            max-width: 768px;
        }
    }
}

@media screen and (max-width: 1200px) {
    .gallery {
        &_content {
            display: none;
        }
    }
}