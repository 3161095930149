.gallery {

    display: none;
    overflow-x: scroll;
    margin-top: 36px;
    width: 100%;
    max-width: 360px;

    &_item {
        width: 100%;
        height: 330px;
    }
}

@media screen and (max-width:768px) {
    .gallery {
        display: flex;
        column-gap: 10px;

    }
}

@media screen and (min-width: 450px) and (max-width:768px) {
    .gallery {
        width: 100%;
        max-width: 100%;
    }
}