.footer {
    width: 100%;
    height: auto;
    background: #000;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: 36px;

    &_title {
        color: #E4A951;
        font-family: BIZ UDPGothic;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 80px;
        text-decoration-line: underline;
    }

    &_map {
        width: 334px;
        height: 334px;
        border-radius: 20px;
        outline: rgba(205, 174, 93, 0.5);
    }



    &_address {
        margin-top: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 16px;

        &_img {
            width: 25px;
            height: 25px;
        }

        &_text {
            width: 200px;
            color: #FFF;
            font-family: Blinker;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px;
        }
    }


    &_social {
        margin-bottom: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 30px;

        &_item {
            width: 40px;
            height: 40px;

            &_link {
                margin-top: 36px;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                background: #E4A951;
                border-radius: 50%;
            }
        }


    }

    &_line {
        height: 1px;
        background: #CDAE5D;
        margin: 0 auto;

        &_up {
            margin-top: 36px;
            width: 180px;

        }

        &_bottom {
            margin-top: 3px;
            width: 100px;
        }
    }



    &_copyright {
        padding: 10px 0;
        color: #FFF;

        font-family: Blinker;
        font-size: 10px;
        font-style: normal;
        font-weight: 300;
        line-height: 28px;
    }
}

.footerL {
    display: none;
    background-color: #000;
}

@media screen and (min-width: 768px) {
    .footer {
        display: none;
    }

    .footerL {
        display: block;

        &_wrapper {
            padding-top: 80px;
            width: 92%;
            max-width: 768px;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            column-gap: 80px;
        }

        &_left {

            &_logo {
                display: flex;
                align-items: center;
                column-gap: 20px;


                svg {
                    width: 70px;
                    height: 96px;
                }

                &_text {
                    position: relative;

                    &_bottom {
                        padding: 5px 0 0 15px;
                    }

                    p {
                        color: #FFF;
                        font-family: BIZ UDPGothic;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 22px;
                    }
                }
            }

            &_title {
                padding-top: 36px;
                color: #E4A951;
                font-family: BIZ UDPGothic;
                font-size: 28px;
                font-style: normal;
                font-weight: 700;
                line-height: 32px;
                text-decoration-line: underline;
            }

            &_info {
                display: flex;
                flex-direction: column;
                row-gap: 36px;
                list-style: none;
                margin-top: 36px;

                &_item {
                    display: flex;
                    column-gap: 18px;

                    &_text {
                        color: #FFF;
                        font-family: Blinker;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 28px;


                    }
                }
            }

            &_social {
                display: flex;
                column-gap: 29px;
                margin-top: 36px;

                &_item {
                    width: 39px;
                    height: 39px;

                    &_link {
                        border-radius: 50%;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background: #E4A951;
                    }
                }
            }
        }

        &_right {
            &_map {
                height: 395px;
                border-radius: 10%;
                border: 2px solid rgba(205, 174, 93, 0.5);
            }
        }

        &_bottom {
            margin: 80px auto 0 auto;
            max-width: 768px;
            width: 92%;


            &_line {
                height: 1px;
                background: #CDAE5D;
                margin: 0 auto;

                &_up {
                    width: 100%;
                }

                &_bottom {
                    width: 80%;
                    margin-top: 5px;
                }
            }

            &_copyright {
                padding: 10px 0;
                text-align: center;
                color: #FFF;
                font-family: Blinker;
                font-size: 16px;
                font-style: normal;
                font-weight: 300;
                line-height: 28px;
            }
        }
    }
}

@media screen and (min-width: 1200px) and (max-width:1439px) {
    .footerL {
        &_wrapper {
            width: 100%;
            max-width: 1200px;
        }
    }
}

@media screen and (min-width: 1440px) {
    .footerL {

        &_wrapper {
            max-width: 1440px;
            width: calc(100% - 230px);
            padding: 80px;
            justify-content: space-between;

        }

        &_left {

            &_logo {
                display: flex;
                align-items: center;
                column-gap: 20px;


                svg {
                    width: 70px;
                    height: 96px;
                }

                &_text {
                    position: relative;

                    &_bottom {
                        padding: 5px 0 0 15px;
                    }

                    p {
                        color: #FFF;
                        font-family: BIZ UDPGothic;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 22px;
                    }
                }
            }

            &_title {
                padding-top: 36px;
                color: #E4A951;
                font-family: BIZ UDPGothic;
                font-size: 28px;
                font-style: normal;
                font-weight: 700;
                line-height: 32px;
                text-decoration-line: underline;
            }

            &_info {
                display: flex;
                flex-direction: column;
                row-gap: 50px;
                list-style: none;
                margin-top: 50px;

                &_item {
                    display: flex;
                    column-gap: 18px;

                    &_text {
                        color: #FFF;
                        font-family: Blinker;
                        font-size: 22px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 28px;

                        &_info {
                            text-decoration: none;
                            color: #FFF;
                        }
                    }
                }
            }

            &_social {
                display: flex;
                column-gap: 29px;
                margin-top: 50px;

                &_item {
                    width: 39px;
                    height: 39px;

                    &_link {
                        border-radius: 50%;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background: #E4A951;
                    }
                }
            }
        }



        &_right {
            &_map {
                width: 595px;
                height: 395px;
                border-radius: 10%;
                border: 2px solid rgba(205, 174, 93, 0.5);
            }
        }
    }

}