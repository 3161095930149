.advantage {
    width: 100%;
    background: #000;

    &_wrapper {

        width: 92%;
        margin: 0 auto;
    }

    &_title {

        text-align: center;
        color: #E4A951;
        font-family: BIZ UDPGothic;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 80px;
        text-decoration-line: underline;
        text-underline-offset: 5px;
    }

    &_cards {
        width: 100%;
        margin: 0 auto;
        display: flex;
        column-gap: 15px;
        overflow-x: scroll;
        padding-bottom: 36px;

        &_item {
            width: 274px;
            height: 348px;
            border-radius: 10px;
            border: 1px solid rgba(205, 174, 93, 0.50);
            padding: 25px 20px 0 20px;
            background: #000;
            transition-duration: 0.5s;

            &:hover {
                transition-duration: 0.4s;
                border: 1px solid rgba(205, 174, 93, 0.80);
                transform: scale(1.1);
            }


            &_up {

                display: flex;
                justify-content: space-around;

                &_title {
                    width: 100px;
                    color: #e3c18dbf;
                    font-family: BIZ UDPGothic;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 30px;
                    text-decoration-line: underline;
                }
            }

            &_text {
                width: 270px;
                padding-top: 40px;
                color: #FFF;
                font-family: Blinker;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 30px;
                letter-spacing: 1.12px;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .advantage {
        &_wrapper {
            max-width: 768px;
        }

        &_title {
            padding-top: 80px;
            text-align: left;
            font-size: 28px;
            line-height: 0;
        }

        &_cards {
            margin-top: 80px;
            gap: 80px;
            overflow-x: visible;
            flex-wrap: wrap;
            justify-content: space-between;

            &_item {
                height: 475px;
                width: 270px;

                &_up {
                    justify-content: space-between;

                    &_title {
                        width: 175px;
                        font-size: 22px;
                        font-style: normal;
                        line-height: 40px;
                    }
                }

                &_text {
                    font-size: 18px;
                    line-height: 35px;
                    letter-spacing: 1.44px;
                }
            }
        }
    }
}

@media screen and (min-width: 1200px) {
    .advantage {
        &_wrapper {
            width: calc(100% - 230px);
            max-width: 1200px;
        }

        &_cards {
            justify-content: center;

            &_item {
                height: 495px;
            }
        }
    }

    .advantage_cards_item_text {
        font-size: 20px;
        line-height: 35px;
        letter-spacing: 1px;
    }
}


@media screen and (min-width: 1440px) {
    .advantage {
        &_wrapper {
            width: calc(100% - 230px);
            padding: 0 80px 80px 80px;
            max-width: 1440px;

            &_title {
                font-size: 28px;
            }

        }

        &_cards {
            gap: 40px 30px;
            justify-content: space-between;

            &_item {
                width: 330px;
                height: 465px;

                &_up {
                    justify-content: flex-start;
                    column-gap: 45px;

                    &_title {
                        font-size: 22px;
                    }
                }

                &_text {
                    width: 100%;

                }
            }

        }
    }
}