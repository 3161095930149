@import "@radix-ui/colors/black-alpha.css";
@import "@radix-ui/colors/mauve.css";
@import "@radix-ui/colors/violet.css";

.AccordionRoot {
  width: 80%;
  margin-top: 50px;
  border-radius: 6px;
  background-color: rgb(18, 18, 18);
}

.AccordionItem {
  overflow: hidden;
  margin-top: 1px;
}

.AccordionItem:first-child {
  margin-top: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.AccordionItem:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.AccordionItem:focus-within {
  position: relative;
  z-index: 1;
}

.AccordionHeader {
  display: flex;
}

.AccordionTrigger {
  font-family: Blinker;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 45px;
  background-color: transparent;
  padding: 10px 20px;
  border-bottom: 1px solid rgba(205, 174, 93, 0.5);
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  background-color: hwb(60 7% 93%);
}

.AccordionTrigger:hover {
  background-color: hwb(60 7% 93%);
}

.AccordionContent {
  overflow: hidden;
  font-family: Blinker;
  color: #989892;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 45px;
  background-color: hwb(60 7% 93%);
}
.AccordionContent[data-state="open"] {
  animation: slideDown 300ms cubic-bezier(0.87, 0, 0.13, 1);
}
.AccordionContent[data-state="closed"] {
  animation: slideUp 300ms cubic-bezier(0.87, 0, 0.13, 1);
}

.AccordionContentText {
  padding: 15px 20px;
}

.AccordionChevron {
  color: rgba(205, 174, 93, 0.5);
  width: 20px;
  height: 20px;
  transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
}
.AccordionTrigger[data-state="open"] > .AccordionChevron {
  transform: rotate(180deg);
}

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
}

@media screen and (max-width: 768px) {
  .AccordionRoot {
    margin-top: 36px;
  }

  .AccordionTrigger {
    font-size: 16px;
  }

  .AccordionContent {
    font-size: 14px;
  }
}

@media screen and (min-width: 900px) {
  .AccordionRoot {
    width: 100%;
  }
}
