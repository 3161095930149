.header {
    position: relative;
    width: 100%;
    height: 529px;
    background: url('./img/hero_vlad.png');
    background-size: 374px;
    background-repeat: no-repeat;
    background-position: center;

    &::after {
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: -1;
        content: '';
        width: 100%;
        height: 100%;
        background: #000;

    }
}

.wrapper {
    width: 100%;
    margin: 0 auto;
    max-width: 320px;
}

@media screen and (min-width: 768px) {
    .wrapper {
        max-width: 768px;
    }

    .header {
        height: 900px;
        background: url(./img/vlad_tablet_bg.png);
        background-size: 768px;
        background-position: center;
        background-repeat: no-repeat;

    }
}

@media screen and (min-width:1200px) {
    .wrapper {
        max-width: 1200px;
    }

}

@media screen and (min-width: 1440px) {
    .header {
        height: auto;
        background: url(./img/hero_desctop.png);
        background-size: 910px;
        background-repeat: no-repeat;
    }

    .wrapper {
        max-width: 1440px;
        width: calc(100% - 160px);
    }


}

@media screen and (min-width: 1441px) {
    .header {
        background: none;

    }

    .wrapper {
        background: url(./img/hero_desctop.png);
        background-size: 910px;
        background-repeat: no-repeat;
    }
}