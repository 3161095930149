.aboutSection {
    background: #131312;
    width: 100%;
    padding: 36px 0;

    &_wrapper {
        max-width: 320px;
        width: 92%;
        margin: 0 auto;

        &_title {
            text-align: center;
            color: #E4A951;
            font-family: BIZ UDPGothic;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;

            text-decoration-line: underline;
        }

        &_img {
            display: block;
            margin: 33px auto 0 auto;
            width: 100%;
            height: 433px;
        }

        &_text {

            padding-top: 16px;
            color: #FFF;

            font-family: Blinker;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
            letter-spacing: 1.12px;
        }
    }

    .right {
        &_img {
            display: none;
        }

        &_imgDesktop {
            display: none;
        }
    }
}

@media screen and (min-width: 768px) {
    .aboutSection {
        padding: 80px 0;

        &_wrapper {
            max-width: 768px;
            display: flex;
            column-gap: 30px;
            justify-content: center;
            align-items: center;

            &_title {
                text-align: left;
                font-size: 28px;
                padding-bottom: 36px;
                /*letter-spacing: -4.2px*/
            }

            &_img {
                display: none;
            }

            &_text {
                font-size: 16px;
                line-height: 25px;
                letter-spacing: 1.28px;

            }
        }
    }

    .right {
        &_imgDesktop {
            display: none;
        }

        &_img {
            display: block !important;
        }
    }
}

@media screen and (min-width: 1200px) {
    .aboutSection {
        &_wrapper {
            max-width: 1200px;
            align-items: center;

            &_title {
                padding-bottom: 64px;
                font-size: 28px;

            }

            &_text {
                font-size: 20px;
            }
        }
    }

}

@media screen and (min-width: 1440px) {
    .aboutSection {
        width: calc(100% - 230px);
        max-width: 1440px;
        margin: 0 auto;
        padding: 80px 0;

        &_wrapper {
            width: 98.5%;
            align-items: center;
            justify-content: space-between;
            margin: 0;
            column-gap: 200px;
            max-width: 1440px;

            &_title {
                padding-bottom: 64px;
                font-size: 28px;

            }

            &_text {
                font-size: 22px;
                letter-spacing: 1.76px;
                line-height: 1.5;
            }
        }
    }

    .right {
        &_img {
            display: none !important;
        }

        &_imgDesktop {
            display: block !important;
        }
    }
}