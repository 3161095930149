.container {
    width: 100%;
    padding: 80px 0;
    background: hwb(60 7% 93%);

    &_wrapper {
        width: calc(100% - 230px);
        max-width: 1440px;
        margin: 0 auto;

        &_title {
            color: #E4A951;
            font-family: BIZ UDPGothic;
            font-size: 28px;
            font-style: normal;
            font-weight: 700;
            text-decoration-line: underline;
        }
    }
}

@media screen and (max-width: 450px) {
    .container {
        padding-bottom: 36px;

        &_wrapper {
            width: 100%;
            max-width: 360px;
        }
    }
}

@media screen and (max-width: 768px) {
    .container {
        padding-top: 36px;

        &_wrapper {
            width: 92%;
            display: flex;
            align-items: center;
            flex-direction: column;
            padding-bottom: 36px;

            &_title {
                text-align: center;
                font-size: 18px
            }
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
    .container {
        width: 100%;

        &_wrapper {
            max-width: 768px;
            width: 92%;
            display: flex;
            flex-direction: column;
            align-items: center;

            &_title {
                align-self: flex-start;
            }
        }
    }
}